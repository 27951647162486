export function normalizeRawGlobalFooterData(footerData) {
  return {
    footerText: footerData.edges[0].node.data.footer_text.html
  }
}

export function normalizeRawGlobalHeaderData(headerData) {
  return {
    linkText: headerData.edges[0].node.data.link_text.text
  }
}

export function normalizeRawHomepageData(homepageData) {
  const { data } = homepageData.edges[0].node
  return {
    hero: {
      title: data.hero_title.text,
      image: data.hero_image,
    },
    manifest: data.manifest_text.html
  }
}