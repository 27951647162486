import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { breakpoint } from '../styles/mixins';
import scrollToElement from 'scroll-to-element'

import Navigation from './navigation'
import Logo from './logo'

const Header = ({ siteTitle, linkText }) => {
  const idToScroll = "#subscriptionPage"
  const scrollToSubscription = () => {
    scrollToElement(idToScroll);
  }
  return (
    <HeaderStyled>
      <HeaderWrapperStyled>
        <HeaderItem>
          <Logo text={siteTitle} />
        </HeaderItem>
      </HeaderWrapperStyled>
    </HeaderStyled>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  linkText: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
  linkText: ''
}

const HeaderStyled = styled.header`
  width: 100%;
`
const HeaderWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  ${breakpoint.lg`
    padding: 32px 0;
  `}
`
const HeaderItem = styled.div`
  flex: 1 1 100%;
  align-items: center;
  display: flex;
`
const HeaderItemRightStyled = styled(HeaderItem)`
  flex: 1 1 100%;
  align-items: center;
  justify-content: flex-end;
  display: flex;
`

export default Header
