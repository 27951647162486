import styledBreakpoint from '@humblebee/styled-components-breakpoint';

// Creates an object with breakpoint utility methods.
export const breakpoint = styledBreakpoint({
  xxs: 0,
  xs: 320,
  s: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
});