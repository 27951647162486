
export const variables = {
  // colors
  primaryColor: '#000',
  secondaryColor: 'rgba(0,0,0,0.4)',
  // font size
  baseFontSize: 16,
  mediumFontSizeDekstop: 32,
  mediumFontSizeTablet: 28,
  mediumFontSizeMobile: 24,
  lineHeight: 1.6,
  // font family
  mediumFamily: '"Graphik-Medium", sans-serif',
  regularFamily: '"Graphik-Regular", sans-serif',
}