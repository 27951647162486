import GraphikMediumOtf from "../fonts/Graphik-Medium.otf"
import GraphikMediumEot from "../fonts/Graphik-Medium.eot"
import GraphikMediumSvg from "../fonts/Graphik-Medium.svg"
import GraphikMediumTtf from "../fonts/Graphik-Medium.ttf"
import GraphikMediumWoff from "../fonts/Graphik-Medium.woff"
import GraphikMediumWoff2 from "../fonts/Graphik-Medium.woff2"

import GraphikRegularOtf from "../fonts/Graphik-Regular.otf"
import GraphikRegularEot from "../fonts/Graphik-Regular.eot"
import GraphikRegularSvg from "../fonts/Graphik-Regular.svg"
import GraphikRegularTtf from "../fonts/Graphik-Regular.ttf"
import GraphikRegularWoff from "../fonts/Graphik-Regular.woff"
import GraphikRegularWoff2 from "../fonts/Graphik-Regular.woff2"

export const fontFace = `
  @font-face {
    font-family: "Graphik-Regular";
    src: url("${GraphikRegularEot}"); /* IE9 Compat Modes */
    src: url("${GraphikRegularEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("${GraphikRegularOtf}") format("opentype"), /* Open Type Font */
      url("${GraphikRegularSvg}") format("svg"), /* Legacy iOS */
      url("${GraphikRegularTtf}") format("truetype"), /* Safari, Android, iOS */
      url("${GraphikRegularWoff}") format("woff"), /* Modern Browsers */
      url("${GraphikRegularWoff2}") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }


  @font-face {
    font-family: "Graphik-Medium";
    src: url("${GraphikMediumEot}"); /* IE9 Compat Modes */
    src: url("${GraphikMediumEot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
      url("${GraphikMediumOtf}") format("opentype"), /* Open Type Font */
      url("${GraphikMediumSvg}") format("svg"), /* Legacy iOS */
      url("${GraphikMediumTtf}") format("truetype"), /* Safari, Android, iOS */
      url("${GraphikMediumWoff}") format("woff"), /* Modern Browsers */
      url("${GraphikMediumWoff2}") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
`
