import PropTypes from "prop-types"
import React from "react"
import parse from 'html-react-parser';
import styled from "styled-components"
import { breakpoint } from '../styles/mixins';
import { variables } from '../styles/variables';

const Footer = ({ footerText }) => (
  <FooterStyled>
   <FooterWrapperStyled>
    <FooterItemStyled>&copy; { new Date().getFullYear() }</FooterItemStyled>
    <FooterItemLastChildStyled>{ parse(footerText) } </FooterItemLastChildStyled>
   </FooterWrapperStyled> 
  </FooterStyled>
)

Footer.propTypes = {
  footerText: PropTypes.string,
}

Footer.defaultProps = {
  footerText: '',
}

const FooterStyled = styled.footer`
  widht: 100%;
`
const FooterWrapperStyled = styled.div`
  display: inline-flex;
  padding: 24px 0;
  font-size: 12px;
  ${breakpoint.md`
    padding: 24px 0;
    font-size: 16px;
  `}
  ${breakpoint.lg`
    padding: 48px 0;
    font-size: 16px;
  `}
  p {
    
    color: ${variables.primaryColor}
    ${breakpoint.lg`
      font-size: 16px;
    `}
  }
  a {
    color: ${variables.primaryColor}
    font-family: ${variables.mediumFamily}
  }
`

const FooterItemStyled = styled.div`
  flex: auto;
`

const FooterItemLastChildStyled = styled(FooterItemStyled)`
  margin-left: 6px;
  display: block;
  
`

export default Footer
