/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { graphql, useStaticQuery} from "gatsby"
import { GlobalStyle } from "../styles/globalStyle"
import { breakpoint } from '../styles/mixins';
import Footer from "./footer"
import { normalizeRawGlobalFooterData, normalizeRawGlobalHeaderData } from '../services'

import Header from "./header"

const Layout = ({ children }) => {
  const [opacity, setOpacity] = useState(0)

  useEffect(() => {
    // Update the document title using the browser API
    setOpacity(1)
  });
  const { 
    allPrismicGlobalFooter,
    allPrismicGlobalHeader,
    site
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        allPrismicGlobalFooter {
          edges {
            node {
              data {
                footer_text {
                  html
                }
              }
            }
          }
        }
        allPrismicGlobalHeader {
          edges {
            node {
              data {
                link_text {
                  text
                }
              }
            }
          }
        }
      }
    `
  )
  const dataGlobalFooter = normalizeRawGlobalFooterData(allPrismicGlobalFooter)
  const dataGlobalHeader = normalizeRawGlobalHeaderData(allPrismicGlobalHeader)
  return (
      <div style={{ opacity }}>
        <GlobalStyle />
        <LayoutWrapperStyled>
          <Header siteTitle={site.siteMetadata.title} linkText={dataGlobalHeader.linkText} />
          <main> {children} </main>
          <Footer footerText={dataGlobalFooter.footerText} />
        </LayoutWrapperStyled>
      </div>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const LayoutWrapperStyled = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 0 24px
  ${breakpoint.md`
    padding: 0 56px
  `}
  ${breakpoint.lg`
    padding: 0 64px
  `}
`

export default Layout