import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "styled-components"
import { variables } from '../styles/variables'

const Logo = ({text}) => {
  return (
    <LogoStlyled>
      <Link to="/">
        {text}
      </Link>
    </LogoStlyled>
  )
}

Logo.propTypes = {
  text: PropTypes.string.isRequired,
  image: PropTypes.object
}

Logo.defaultProps = {
  text: '',
  image: {}
}


const LogoStlyled = styled.h1`
  width: auto;
  font-size: ${variables.mediumFontSizeMobile}px;
  font-family: ${variables.mediumFamily};
  a {
    position: relative;
    color: ${variables.primaryColor};
    font-family: ${variables.mediumFamily};
    display: inline-block;
    text-decoration: none;
    &:after {
      content: ' ';
      height: 2px;
      background-color: ${variables.primaryColor};
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
  
`

export default Logo
