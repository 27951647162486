
import { createGlobalStyle } from "styled-components"
import { resetCss } from './reset'
import { normalizeCss } from './normalize'
import { fontFace } from './fontFace'
import { variables } from './variables'

export const GlobalStyle = createGlobalStyle`
  ${fontFace}
  ${normalizeCss}
  ${resetCss}
  body {
    font-family: ${variables.regularFamily};
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`

export const blockDivStyled = `
  width: 100%;
`
export const blockWrapperStyled = `
  display: flex;
  flex: 1 1 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`
export const blockItemStyled = `
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  height: 100%;
`
